import { createApp } from 'vue'
import App from './App.vue'

import { firebase } from '@firebase/app';
import "firebase/firestore";
import "firebase/analytics";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faLinkedin)
library.add(faGithub)

const firebaseConfig = {
    apiKey: "AIzaSyBnheY_Ytrtjx8v1eFjIEEOMxfewd-UA1Q",
    authDomain: "portfolio-2b98f.firebaseapp.com",
    databaseURL: "https://portfolio-2b98f.firebaseio.com",
    projectId: "portfolio-2b98f",
    storageBucket: "portfolio-2b98f.appspot.com",
    messagingSenderId: "631664110511",
    appId: "1:631664110511:web:2c28f223195f7239e0dd5a",
    measurementId: "G-Y3Y24SGJN2"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
