<template>
  <div class="menu">
      <div class="logo magic-hover">Will.</div>
      <div class="social">
          <font-awesome-icon class="magic-hover social-item" :icon="['fab', 'linkedin']" />
          <font-awesome-icon class="magic-hover social-item" :icon="['fab', 'github']" />
      </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
};
</script>

<style>
@import "../assets/css/menu.css";
</style>