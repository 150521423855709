<template>
  <Base />
</template>

<script>
import Base from "./components/Base.vue";

export default {
  name: "App",
  components: {
    Base,
  }
};
</script>

<style>
@import "assets/css/reset.css";
@import "assets/css/fonts.css";

html,
body,
#app {
  height: 100vh;
}

#app {
  font-family: "Gilroy", Helvetica, Arial, sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 320px) {
  html {
    font-size: calc(2em + 6 * ((100vw - 320px) / 680));
  }
}
@media screen and (min-width: 1000px) {
  html {
    font-size: 4em;
  }
}
@media screen and (min-width: 2000px) {
  html {
    font-size: 5em;
  }
}
</style>
